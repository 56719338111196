// const moment = require('moment');
// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
// gsap.registerPlugin(ScrollTrigger);
import Splide from '@splidejs/splide';
export function index() {

	let $windowWidth = window.innerWidth;

	let $breakPointA = 480;
	let $breakPointB = 768;

	let $isMobileSize = ($windowWidth < $breakPointA);
	// let $isTabletSize = ($windowWidth <= $breakPointB) && ($windowWidth > $breakPointA);
	let $isTabletSize = ($windowWidth <= $breakPointB);
	let $isPcSize = ($windowWidth > $breakPointB);




	//

	document.addEventListener('DOMContentLoaded', () => {
		const elm = document.querySelector('.toppage__mainimage');

		elm.classList.add('is_active');


	});

	//
	document.addEventListener('DOMContentLoaded', () => {
		const elm = document.querySelectorAll('.split_text');
		for (let t = 0; t < elm.length; t++) {

			const cut = elm[t].innerHTML.trim().split("");
			elm[t].innerHTML = cut.reduce((i, j) => {
				j = j.replace(/\s+/, '&nbsp;');//replace：置換
				return `${i}<span>${j}</span>`;

			}, "");
		}
	});

	window.addEventListener(
		'scroll',
		throttle(100, () => {
			getSplittext();
			getCopytext();
			getBackground();
			getNewstelop();
		}),
		false
	);

	//
	const getBackground = () => {
		const scroll_target = {
			'#service': document.querySelector('.toppage__service'),
			'#news': document.querySelector('.toppage__news'),
			'#recruit': document.querySelector('.toppage__recruit'),
		};
		for (let key of Object.keys(scroll_target)) {
			let clientRect = scroll_target[key].getBoundingClientRect();
			let y = clientRect.top;
			// console.log(key);

			let num = !$isMobileSize ? 500 : 300;

			if (y <= num) {
				if (key === "#service" && !document.querySelector('.toppage__background').classList.contains('.is_gray')) {
					document.querySelector('.toppage__background').classList.add('is_gray');
				} else {
					document.querySelector('.toppage__background').classList.remove('is_gray');
				}
				if (key === "#news" && !document.querySelector('.toppage__background').classList.contains('.is_white')) {
					document.querySelector('.toppage__background').classList.add('is_white');
				} else {
					document.querySelector('.toppage__background').classList.remove('is_white');
				}
				if (key === "#recruit" && !document.querySelector('.toppage__background').classList.contains('.is_white')) {
					document.querySelector('.toppage__background').classList.add('is_white');
				} else {
					document.querySelector('.toppage__background').classList.remove('is_white');
				}
			} else {
				if (key === "#service") {
					document.querySelector('.toppage__background').classList.remove('is_gray');
				}
				if (key === "#news") {
					document.querySelector('.toppage__news').classList.remove('is_black');
				}
			}
		}
	}
	const getCopytext = () => {
		const elm = document.querySelectorAll('.toppage__copy span');
		for (let t = 0; t < elm.length; t++) {
			let clientRect = elm[t].getBoundingClientRect();
			let y = clientRect.top;


			let num = !$isMobileSize ? 500 : 300;

			let count = y / num - 1;
			let count2;
			count = (1 - count) * 100 + "%";
			elm[t].style.setProperty('--co', count);

			// console.log(t + ":" + y);

			count2 = y / 1.5 - num;

			if (count2 >= 0) {
				elm[t].style.setProperty('--tf', count2 + "%");
			} else {
				elm[t].style.setProperty('--tf', "0%");
			}


		}
	}
	const getSplittext = () => {
		const elm = document.querySelectorAll('.split_text');

		for (let t = 0; t < elm.length; t++) {
			let clientRect = elm[t].getBoundingClientRect();
			let y = clientRect.top;
			// console.log(window.innerHeight);
			// let num = getDeviceType() === 'lg' ? 750 : 600;


			// let num = 600;
			let num = window.innerHeight / 2;

			if (y <= num) {
				if (!elm[t].classList.contains('is_animation')) {
					elm[t].classList.add('is_animation');
				}
			} else {
				elm[t].classList.remove('is_animation');
			}
			/*
			*/
		}
	}
	//
	const getNewstelop = () => {
		const elm = document.querySelector('.footer');
		const newstelop = document.querySelector('.toppage__newstelop');
		let clientRect = elm.getBoundingClientRect();
		let y = clientRect.top;
		let num = window.innerHeight;

		if (y <= num) {
			if (!newstelop.classList.contains('is_none')) {
				newstelop.classList.add('is_none');
			}
		} else {
			newstelop.classList.remove('is_none');
		}

	}

	//

	function throttle(delay, fn) {
		// 初回実行の為、runTimeに代入する値は、現在時刻より前に設定
		let runTime = Date.now() - delay
		return () => {
			// 前回実行した時間 + 間引き時間が、現在の時間より前の場合、引数に渡した関数を実行
			if ((runTime + delay) < Date.now()) {
				runTime = Date.now()
				fn()
			}
		}
	}

	//



	document.addEventListener('DOMContentLoaded', function () {

		if ($isTabletSize) {
		}

		$(document).on('click', '.toppage__copy__more a', function () {
			$('.toppage__copy .more').slideToggle();
			$('.toppage__copy__more').toggleClass('is_active');
			if ($('.toppage__copy__more').hasClass('is_active')) {
				$(this).html('Close');
			} else {
				$(this).html('View More');
			}
			return false;
		})
	});


	$.each($('.icon'), function (index, element) {
		$(element).hover(function () {
			$('.hover.' + $(element).data('icon')).addClass('is_active');
		}, function () {
			$('.hover').removeClass('is_active');
		});

		// マウス座標を吹き出しにセット
		$(element).mousemove(function (e) {
			$('.hover.' + $(element).data('icon')).css({
				'top': $(element).position().top + e.offsetY - $('.hover.' + $(element).data('icon')).height() - 30,
				'left': $(element).position().left + e.offsetX - ($('.hover.' + $(element).data('icon')).width() / 2)
			});
		});
	});

}
