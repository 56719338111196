export function tab($baseClass) {
	const tabs = document.querySelectorAll($baseClass + " .tab__link");
	const tab_contents = document.querySelectorAll($baseClass + " .tab__item");
	console.log(tabs);
	console.log(tab_contents);

	for (let i = 0; i < tabs.length; i++) {
		tabs[i].addEventListener("click", function (e) {
			e.preventDefault();
			console.log(tabs.length);

			for (let j = 0; j < tabs.length; j++) {
				tabs[j].classList.remove("is_active");
			}
			for (let j = 0; j < tabs.length; j++) {
				tab_contents[j].classList.remove("is_active");
			}
			tabs[i].classList.add("is_active");
			tab_contents[i].classList.add("is_active");
		});
	}

}
