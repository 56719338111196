import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
import { throttle } from "../components/setting";

export function recruit() {

	let $windowWidth = window.innerWidth;

	let $breakPointA = 480;
	let $breakPointB = 768;

	let $isMobileSize = ($windowWidth < $breakPointA);
	// let $isTabletSize = ($windowWidth <= $breakPointB) && ($windowWidth > $breakPointA);
	let $isTabletSize = ($windowWidth <= $breakPointB);
	let $isPcSize = ($windowWidth > $breakPointB);

	const getCopytext = () => {
		const elm = document.querySelectorAll('.recruittoppage__copy span');
		for (let t = 0; t < elm.length; t++) {
			let clientRect = elm[t].getBoundingClientRect();
			let y = clientRect.top;


			let num = !$isMobileSize ? 500 : 300;

			let count = y / num - 1;
			let count2;
			count = (1 - count) * 100 + "%";
			elm[t].style.setProperty('--co', count);

			// console.log(t + ":" + y);

			count2 = y / 1.5 - num;

			if (count2 >= 0) {
				elm[t].style.setProperty('--tf', count2 + "%");
			} else {
				elm[t].style.setProperty('--tf', "0%");
			}


		}
	}

	const getSplittext = () => {
		const elm = document.querySelectorAll('.split_text');

		for (let t = 0; t < elm.length; t++) {
			let clientRect = elm[t].getBoundingClientRect();
			let y = clientRect.top;
			// console.log(window.innerHeight);
			// let num = getDeviceType() === 'lg' ? 750 : 600;


			// let num = 600;
			let num = window.innerHeight / 1.5;

			if (y <= num) {
				if (!elm[t].classList.contains('is_animation')) {
					elm[t].classList.add('is_animation');
				}
			} else {
				elm[t].classList.remove('is_animation');
			}
			/*
			*/
		}
	}

	if (document.querySelector('.voiceSwiper')) {
		var swiper = new Swiper(".voiceSwiper", {
			slidesPerView: "auto",
			spaceBetween: 20,
			centeredSlides: true,
			loop: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				// 768px以上の場合
				768: {
					centeredSlides: false,
					spaceBetween: 75,
					slidesPerView: 3,
				}
			}
		});
	}
	if (document.querySelector('.recruitvoicepage__categorylist')) {
		let allId = document.getElementById('all');
		let widget = document.getElementById('js-filter');
		let checkboxes = widget.querySelectorAll('.recruitvoicepage__categorylist input[type="checkbox"]');
		let checkedList = [];
		let filter = function () {
			checkedList = [];

			Array.prototype.forEach.call(checkboxes, function (input) {
				if (input.checked) {
					checkedList.push(input.value);
				}
			});

			widget.setAttribute('data-filter-view', checkedList.join(' '));
			if (checkedList.join(' ') !== '') {
				allId.checked = false;
			} else {
				allId.checked = true;
			}
		};

		Array.prototype.forEach.call(checkboxes, function (checkbox) {
			checkbox.addEventListener('change', filter);
		});

	}
	if (document.querySelector('.recruittoppage')) {
		var mainswiper = new Swiper(".recruittoppage__mainimage__voice", {
			loop: true,
			autoplay: {
				delay: 5000,
			},
			speed: 2000,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
		});


		$(document).on('click', '.recruittoppage__copy__more a', function () {
			$('.recruittoppage__copy .more').slideToggle();
			$('.recruittoppage__copy__more').toggleClass('is_active');
			if ($('.recruittoppage__copy__more').hasClass('is_active')) {
				$(this).html('Close');
			} else {
				$(this).html('View More');
			}
			return false;
		})
		if ($isTabletSize) {
			var swiper = new Swiper(".mySwiper", {
				slidesPerView: "auto",
				centeredSlides: true,
				spaceBetween: 10,
				loop: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
			});
		}

		window.addEventListener(
			'scroll',
			throttle(100, () => {
				getCopytext();
				getSplittext();
			}),
			false
		);

		document.addEventListener('DOMContentLoaded', () => {
			const elm = document.querySelectorAll('.mainimage_text');
			for (let t = 0; t < elm.length; t++) {

				const cut = elm[t].innerHTML.trim().split("");
				elm[t].innerHTML = cut.reduce((i, j) => {
					j = j.replace(/\s+/, '&nbsp;');//replace：置換
					return `${i}<span>${j}</span>`;

				}, "");
			}

			for (let t = 0; t < elm.length; t++) {
				setTimeout(() => {
					elm[t].classList.add('is_animation');
				}, 500);
			}
		})
	}


	window.addEventListener('load', () => {
		if (document.querySelector('.recruitpage__header')) {
			// document.querySelector('.recruitpage__header').classList.add('is_load');
			const split_elm = document.querySelectorAll('.split_text');
			for (let t = 0; t < split_elm.length; t++) {
				setTimeout(() => {
					split_elm[t].classList.add('is_animation');
				}, 500);
			}
		}
	});


	if (document.querySelector('.recruitnewgraduatespage')) {
		const recruitNewGraduatespageMessage = document.querySelector('.recruitnewgraduatespage__message .btn a');
		recruitNewGraduatespageMessage.addEventListener('click', e => {
			e.preventDefault();
			document.querySelector('.recruitnewgraduatespage__message__popup').classList.toggle('is_active');
		})

		const recruitNewGraduatespageMessagePopupClose = document.querySelector('.recruitnewgraduatespage__message__popup .close a');
		recruitNewGraduatespageMessagePopupClose.addEventListener('click', e => {
			e.preventDefault();
			document.querySelector('.recruitnewgraduatespage__message__popup').classList.toggle('is_active');
		})
	}


	if (document.querySelector('.recruitcareerrecruitpage')) {
		const recruitcareerrecruitpageMessage = document.querySelector('.recruitcareerrecruitpage__message .btn a');
		recruitcareerrecruitpageMessage.addEventListener('click', e => {
			e.preventDefault();
			document.querySelector('.recruitcareerrecruitpage__message__popup').classList.toggle('is_active');
		})

		const recruitcareerrecruitpageMessagePopupClose = document.querySelector('.recruitcareerrecruitpage__message__popup .close a');
		recruitcareerrecruitpageMessagePopupClose.addEventListener('click', e => {
			e.preventDefault();
			document.querySelector('.recruitcareerrecruitpage__message__popup').classList.toggle('is_active');
		})
	}



	if (document.querySelector('.recruitinternshippage')) {
		$(document).on('click', '.recruitinternshippage .question', function () {
			$(this).next().slideToggle();
			$(this).toggleClass('is_active');
			return false;
		})
	}

	//


	if (document.querySelector('.recruitculturepage')) {
		let swiperList = document.querySelectorAll('.swiper');

		for (let t = 0; t < swiperList.length; t++) {
			let item = swiperList[t].dataset.swiper;
			let tab = document.querySelector('.' + item + '_tab');
			let tabChildren = tab.children;

			const swiper = new Swiper(swiperList[t], {
				slidesPerView: 1,
				loop: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
			});
			swiper.on('slideChange', function () {
				console.log(swiper.activeIndex);
				for (let i = 0; i < tabChildren.length; i++) {
					tabChildren[i].firstElementChild.classList.remove('is_active');
				}
				document.querySelector('.' + item + '_' + swiper.activeIndex).classList.add('is_active');
			});
			document.querySelector('.' + item + '_1').addEventListener('click', function (e) {
				e.preventDefault();
				swiper.slideTo(1, 0);
			});
			document.querySelector('.' + item + '_2').addEventListener('click', function (e) {
				e.preventDefault();
				swiper.slideTo(2, 0);
			});
			document.querySelector('.' + item + '_3').addEventListener('click', function (e) {
				e.preventDefault();
				swiper.slideTo(3, 0);
			});
			document.querySelector('.' + item + '_4').addEventListener('click', function (e) {
				e.preventDefault();
				swiper.slideTo(4, 0);
			});
		}
	}
	if (document.querySelector('.recruitaboutpage')) {

		$.each($('.recruitaboutpage__business__target area'), function (index, element) {
			const name = $(element).data('popup');


			var coords = $(element).attr('coords').split(',');
			console.log('over', coords);
			var thisLeft = coords[0];
			var thisTop = coords[1];
			var thisheight = coords[2];
			/*
			for (var i = 0; i < coords.length; i++) {
				if (i % 2 == 0) {
					thisLeft = Math.min(thisLeft, coords[i]);
				} else {
					thisTop = Math.min(thisTop, coords[i]);
				}
			}
			*/
			$('.' + name).css({
				top: (Number(thisTop) + Number(thisheight)) + 'px',
				left: (Number(thisLeft)) + 'px'
			})
			$(element).hover(function () {
				$('.' + name).addClass('is_active');
			}, function () {
				$('.popup').removeClass('is_active');
			})
		});


		const autoHeight = () => {

			//idがelemの要素を取得
			let elem = document.querySelectorAll('.case');
			//高さの最大値を代入する変数を初期化
			let elemMaxHeight = 0;
			//elemの子要素の高さを格納する配列を初期化
			let elemArray = new Array;

			//elemの子要素をループ
			Array.prototype.forEach.call(elem, function (elemChild) {

				//子要素の高さのスタイルを初期化（リサイズ対応用）
				elemChild.style.height = '';
				//elemの各子要素の高さを取得
				elemArray.push(elemChild.clientHeight);

			});

			//配列に格納した高さの最大値を取得
			elemMaxHeight = Math.max.apply(null, elemArray);

			//elemの子要素をループ
			Array.prototype.forEach.call(elem, function (elemChild) {
				//elemの子要素のheightにelemMaxHeightを設定
				elemChild.style.height = elemMaxHeight + 'px';

			});
		}

		//ロードとリサイズ時に関数autoHeightを実行
		window.addEventListener('load', autoHeight);
		window.addEventListener('resize', autoHeight);


	}
}
