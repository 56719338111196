import { index } from "./components/index";
import { company } from "./components/company";
import { service } from "./components/service";
import { recruit } from "./components/recruit";
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';
import { throttle } from "./components/setting";
import ScrollHint from 'scroll-hint'
new ScrollHint('.js-scrollable', {
	scrollHintIconAppendClass: 'scroll-hint-icon-white', // white-icon will appear
	applyToParents: true,
	i18n: {
		scrollable: 'スクロールできます'
	}
});

datepickerFactory($);
datepickerJAFactory($);

if (document.querySelector('.toppage')) {
	index();
}
company();
recruit();
service();


$(function () {

	let $windowWidth = window.innerWidth;

	let $breakPointA = 480;
	let $breakPointB = 768;

	let $isMobileSize = ($windowWidth < $breakPointA);
	// let $isTabletSize = ($windowWidth <= $breakPointB) && ($windowWidth > $breakPointA);
	let $isTabletSize = ($windowWidth <= $breakPointB);
	let $isPcSize = ($windowWidth > $breakPointB);

	$(document).on('click', '.header__bar', function () {
		$('.header').toggleClass('is_active');
		$('.nav__sitemap').toggleClass('is_active');
		$('.nav__search').removeClass('is_active');
		if (document.querySelector('.recruitpage__headernav')) {
			$('.recruitpage__headernav').toggleClass('is_hide');
		}
	})


	$(document).on('mouseenter', '.gnav .has_child', function () {
		$(this, '.subnav').addClass('is_active');
	})
	$(document).on('mouseleave', '.gnav .has_child', function () {
		$(this, '.subnav').removeClass('is_active');
	})
	$(document).on('click', '.is_accordion>a', function () {
		$(this).next().slideToggle();
		$(this).parent().toggleClass('is_open');
		return false;
	})


	if ($isTabletSize) {
		//タブレットサイズの場合の記述

		$(document).on('click', '.sitemap a .icon', function () {
			$(this).parent().parent().next().slideToggle();
			$(this).toggleClass('is_active');
			return false;
		})


		$(document).on('click', '.nav__sitemap .category a .icon', function () {
			$(this).parent().parent('.category').next().slideToggle();
			$(this).parent().toggleClass('is_active');
			return false;
		})
	}

	//
	$('.datepicker').datepicker({
		minDate: '+1d',
	});
	$.datepicker.regional['ja'];

	//

	$(document).on('click', '.inpage__sidenav .has_child a .icon', function () {
		if (!$(this).parent().parent().hasClass('is_active') && !$(this).parent().parent().hasClass('is_open')) {
			$(this).parent().next().slideToggle();
			// $(this).next().parent().toggleClass('is_active');
			$(this).parent().toggleClass('is_active');
		}
		return false;
	})


	window.addEventListener(
		'scroll',
		throttle(100, () => {

			let clientRect = document.querySelector('body').getBoundingClientRect();
			let y = clientRect.top;
			// console.log(y);
			if (y <= $(window).height() * -1) {
				$(".pagetop").addClass('is_active');
			} else {
				$(".pagetop").removeClass('is_active');
			}
		}),
		false
	);


	//

	$(".pagetop a").on('click', function () {
		$('html, body').animate({
			scrollTop: $($(this).attr("href")).offset().top
		}, 2000);
		return false;
	})
	$(".anker").on('click', function () {
		$('html, body').animate({
			scrollTop: $($(this).attr("href")).offset().top - $('.header').outerHeight()
		}, 2000);
		return false;
	})


})

if (!document.querySelector('.toppage')) {

	document.addEventListener('DOMContentLoaded', () => {

		const split_elm = document.querySelectorAll('.split_text');
		for (let t = 0; t < split_elm.length; t++) {

			const cut = split_elm[t].innerHTML.trim().split("");
			split_elm[t].innerHTML = cut.reduce((i, j) => {
				j = j.replace(/\s+/, '&nbsp;');//replace：置換
				return `${i}<span>${j}</span>`;

			}, "");
		}
	});

	window.addEventListener('load', () => {
		if (document.querySelector('.inpage__header')) {
			document.querySelector('.inpage__header').classList.add('is_load');
			const split_elm = document.querySelectorAll('.split_text');
			for (let t = 0; t < split_elm.length; t++) {
				setTimeout(() => {
					split_elm[t].classList.add('is_animation');
				}, 500);
			}
		}
		if (document.querySelector('.inpage__serviceheader')) {
			document.querySelector('.inpage__serviceheader').classList.add('is_load');
			const split_elm = document.querySelectorAll('.split_text');
			for (let t = 0; t < split_elm.length; t++) {
				setTimeout(() => {
					split_elm[t].classList.add('is_animation');
				}, 500);
			}
		}
	});

}


window.addEventListener(
	'scroll',
	throttle(100, () => {
		getFadeup();
		getBackground();
	}),
	false
);

const getFadeup = () => {
	const elm = document.querySelectorAll('.fadeup_animation');
	for (let t = 0; t < elm.length; t++) {
		let clientRect = elm[t].getBoundingClientRect();
		let y = clientRect.top;
		let num = window.innerHeight / 1.25;

		if (y <= num) {
			if (!elm[t].classList.contains('is_animation')) {
				elm[t].classList.add('is_animation');
			}
		} else {
			elm[t].classList.remove('is_animation');
		}
	}

}

const getBackground = () => {
	const scroll_target = {
		'#gray': document.querySelectorAll('.inpage__background__gray'),
		'#white': document.querySelectorAll('.inpage__background__white'),
		'#black': document.querySelectorAll('.inpage__background__black'),
	};
	for (let key of Object.keys(scroll_target)) {
		if (scroll_target[key].length > 0) {
			for (let t = 0; t < scroll_target[key].length; t++) {
				if (scroll_target[key][t]) {
					let clientRect = scroll_target[key][t].getBoundingClientRect();
					let y = clientRect.top;
					let num = 500;
					if (y <= num) {
						scroll_target[key][t].classList.add('is_active');
						if (document.querySelector('.servicepage__single')) {
							if (key === "#black") {
								document.querySelector('.servicepage__single').classList.add('is_color_black');
							}
						}
					} else {
						scroll_target[key][t].classList.remove('is_active');
						if (document.querySelector('.servicepage__single')) {
							if (key === "#black") {
								document.querySelector('.servicepage__single').classList.remove('is_color_black');
							}
						}
					}
				} else {
					if (scroll_target[key][t] !== null) {
						scroll_target[key][t].classList.remove('is_active');
					}
				}
			}
		}
	}
}


if (document.querySelector('.newspage__single .body')) {
	//ページのドメインを取得
	const domain = document.domain;
	// console.log(domain);
	//取得したドメインを検証対象に指定
	const regexp = new RegExp(domain);

	//特定の要素内に存在するa要素が対象
	const target = document.querySelector('.newspage__single .body');
	const elements = target.getElementsByTagName('a');

	//ページ内に存在する全てのa要素が対象
	// const elements = document.getElementsByTagName('a');

	for (let element of elements) {
		//a要素のhref（リンク）を取得
		let href = element.getAttribute('href');
		// console.log(href);
		//a要素のhref（リンク）にドメインが含まれていなければ
		if (!regexp.test(href)) {
			//a要素にtarget="_blank"を追加する
			// element.setAttribute('target', '_blank');
			//a要素にrel="noopener noreferrer"を追加する
			// element.setAttribute('rel', 'noopener noreferrer');
		}
	}
}



if (document.querySelector('.servicesinglepage__subcontent__body')) {
	//ページのドメインを取得
	const domain = document.domain;
	// console.log(domain);
	//取得したドメインを検証対象に指定
	const regexp = new RegExp(domain);

	//特定の要素内に存在するa要素が対象
	const target = document.querySelector('.servicesinglepage__subcontent__body');
	const elements = target.getElementsByTagName('a');

	//ページ内に存在する全てのa要素が対象
	// const elements = document.getElementsByTagName('a');

	for (let element of elements) {
		//a要素のhref（リンク）を取得
		let href = element.getAttribute('href');
		// console.log(href);
		//a要素のhref（リンク）にドメインが含まれていなければ
		if (!regexp.test(href)) {
			//a要素にtarget="_blank"を追加する
			element.setAttribute('target', '_blank');
			//a要素にrel="noopener noreferrer"を追加する
			element.setAttribute('rel', 'noopener noreferrer');
		}
	}
}

const searchBtn = document.querySelector('.nav .search');
searchBtn.addEventListener('click', e => {
	e.preventDefault();
	searchBtn.classList.toggle('is_active');
	document.querySelector('.nav__search').classList.toggle('is_active');
	$('.header').removeClass('is_active');
	$('.nav__sitemap').removeClass('is_active');
})


const headerSearchBtn = document.querySelector('.header__search');
headerSearchBtn.addEventListener('click', e => {
	e.preventDefault();
	headerSearchBtn.classList.toggle('is_active');
	document.querySelector('.nav__search').classList.toggle('is_active');
	$('.header').removeClass('is_active');
	$('.nav__sitemap').removeClass('is_active');
})

// contact
const result = document.getElementsByClassName("mw_wp_form_confirm");
if (result.length === 0) {
	const elements = document.getElementsByClassName("btn back");
	console.log(result.length, elements);
	for (let i = 0; i < elements.length; i++) {
		elements[i].style.display = "none";
	}
}


const elements = document.querySelectorAll('.js-scroll-x')
elements.forEach(element => {
	if (window.innerWidth <= 1200) {
		element.style.left = '0'
	}
	window.addEventListener('resize', () => {
		if (window.innerWidth >= 1200) {
			element.style.left = null;
		}
	})

	window.addEventListener('scroll', () => {
		if (window.innerWidth <= 1200) {
			element.style.left = -window.pageXOffset + 'px'
		}
	})
})
