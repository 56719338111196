import matchHeight from 'jquery-match-height';
import LocomotiveScroll from 'locomotive-scroll';

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { throttle } from "./setting";

export function company() {
	$(function () {
		$('.memberpage__list.senior .position').matchHeight();


		//
		if (document.querySelector('.domainpage__point')) {


			window.addEventListener(
				'scroll',
				throttle(100, () => {
					let clientRect = document.querySelector('.domainpage__point').getBoundingClientRect();
					let y = clientRect.top;
					let num = 500;
					if (y <= num) {
						document.querySelector('.inpage__background__gray').classList.add('is_white');
					} else {
						document.querySelector('.inpage__background__gray').classList.remove('is_white');
					}
				}),
				false
			);
		}
	});
}
