export function setting() {

}



export function throttle(delay, fn) {
	// 初回実行の為、runTimeに代入する値は、現在時刻より前に設定
	let runTime = Date.now() - delay
	return () => {
		// 前回実行した時間 + 間引き時間が、現在の時間より前の場合、引数に渡した関数を実行
		if ((runTime + delay) < Date.now()) {
			runTime = Date.now()
			fn()
		}
	}
}
